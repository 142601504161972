import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import "./productSearch/TerapeakData.css"

function TerapeakData({ isError, displayData }) {
  
  return (
    <div className='terapeak-container'>
      {!isError && displayData ? (
        <>
          <div className="agg-container">
            {displayData && displayData.aggContents ? (
              <div className="agg-stats">
                <div className="agg-item">
                  <h3>{displayData.aggContents[0]?.value}</h3>
                  <p>平均販売価格</p>
                </div>
                <div className="agg-item">
                  <h3>{displayData.total_sold}</h3>
                  <p>総販売数</p>
                </div>
                <div className="agg-item">
                <h3>
                {
                  displayData.reference_price
                }円
                </h3>
                  <p>参考価格</p>
                </div>
              </div>
            ) : (
              <div>まだ利用可能なデータはありません。</div>
            )}
          </div>

          <div className="items-section">
            <ul>
              {displayData.itemData.map((item, index) => (
                <li key={index} className='item-card'>
                  <h4>
                    <a href={item.webUrl} target="_blank" rel="noopener noreferrer">
                      {item.title}
                    </a>
                  </h4>
                  {
                    item.imageUrl !== 'noImage' ? <img src={item.imageUrl} alt={item.title} /> : <div>no image</div>
                  }

                  <table className='table'>
                    <thead>
                      <tr>
                        <th>販売価格</th>
                        <th>実価格</th>
                        <th>総販売数</th>
                        <th>最終販売</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><div className='sold-price'><div>{item.soldPrice}</div><div className='format'>{item.soldMethod}</div></div></td>
                        <td>{item.itemSales}</td>
                        <td>{item.totalSold}</td>
                        <td>{item.lastSold}</td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <div>まだ利用可能なデータはありません。</div>
      )}
    </div>
  );
}

export default TerapeakData;
