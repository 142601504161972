import React from 'react';
import './productSearch/AuctionTypeSelector.css'; // Custom styling

const AuctionTypeSelector = ({ selectedType, setSelectedTypeHandler }) => {
  const handleChange = (e) => {
    setSelectedTypeHandler(e.target.name);
  };

  return (
    <div className="auction-type-selector card p-3">
      <div className="checkbox-buttons-container">
        <fieldset>
        
          <legend>落札形式を選択してください</legend>
          <div className='auction-item'>
            <label className="toggle-switch square">
              <input 
                type="checkbox"
                name='auction'
                checked={selectedType.includes('auction')}
                onChange={handleChange} 
              />
              <span className="slider"></span>
              <span className="label-text">オークション</span>
            </label>
          </div>

          <div className='auction-item'>
          <label className="toggle-switch square">
            <input 
              type="checkbox" 
              name='best'
              checked={selectedType.includes('best')}
              onChange={handleChange}  
            />
            <span className="slider"></span>
            <span className="label-text">ベストオファー</span>
          </label>
          </div>

          <div className='auction-item'>
          <label className="toggle-switch square">
            <input 
              type="checkbox" 
              name='fixed'
              checked={selectedType.includes('fixed')}
              onChange={handleChange}
            />
            <span className="slider"></span>
            <span className="label-text">固定価格</span>
          </label>
          </div>

        
        </fieldset>
      </div>
    </div>
  );
};

export default AuctionTypeSelector;
